import Joi from "joi";
import errorMessages from "./errorMessages.json";

export default Joi.object({
  name: Joi.string()
    .max(50)
    .required(),
  iso_code: Joi.string()
    .max(50)
    .required()
})
  .unknown()
  .messages(errorMessages);
