<template>
  <div class="admin-languages">
    <portal to="page-call-to-action">
      <ebp-button @click="showAddLanguage = true">{{
        $t("add-language")
      }}</ebp-button>
    </portal>
    <advanced-data-table
      action="languages/paginate"
      :headers="headers"
      ref="table"
      :limit="50"
    >
      <template #cell(parent_channel)="{ item }">{{
        item.parent_channel ? item.parent_channel.name : ""
      }}</template>
      <template #cell(does_allow_free_listing)="{ item }">{{
        $t(item.does_allow_free_listing ? "yes" : "no")
      }}</template>
      <!-- Actions -->
      <template #actions="{ item }">
        <b-button
          variant="primary"
          size="sm"
          @click="edit(item)"
          v-tooltip.top-center="$t('edit')"
        >
          <i class="bx bx-pencil"></i>
        </b-button>
        <b-button
          outlined
          variant="danger"
          size="sm"
          class="ml-1"
          @click="remove(item)"
          v-tooltip.top-center="$t('delete')"
        >
          <i class="bx bxs-trash"></i>
        </b-button>
      </template>
    </advanced-data-table>

    <!-- SalesChannel edit modal -->
    <ebp-modal :open.sync="open" :tabs="editTabs">
      <template #error><error :err.sync="error"/></template>

      <!-- Edit details -->
      <template #edit-language>
        <EditLanguage :language="editedItem" />
      </template>
    </ebp-modal>

    <!-- SalesChannel add modal -->
    <ebp-modal :open.sync="showAddLanguage" :tabs="addTabs">
      <template #error><error :err.sync="error"/></template>

      <!-- Edit details -->
      <template #add-language>
        <AddLanguage @added="added" />
      </template>
    </ebp-modal>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";
import languageSchema from "@/validation-schemas/language";

import AddLanguage from "@/views/admin/system/languages/Add";
import EditLanguage from "@/views/admin/system/languages/Edit";

export default {
  name: "admin-languages",
  components: {
    AdvancedDataTable,
    AddLanguage,
    EditLanguage
  },
  data() {
    return {
      headers: ["name", { key: "iso_code", label: "iso-code" }],
      editedItem: null,
      saving: false,
      open: false,
      showAddLanguage: false,
      error: null,
      editTabs: ["edit-language"],
      addTabs: ["add-language"]
    };
  },
  methods: {
    added() {
      this.showAddLanguage = false;
      this.$refs.table.currentPage = 1;
      this.$refs.table.get();
    },
    async remove(language) {
      if (confirm(this.$t("remove-message", { name: language.name }))) {
        try {
          await this.$store.dispatch("languages/delete", language.id);
          this.$refs.table.change("id", language.id);
        } catch (err) {
          console.error(err);
        }
      }
    },
    edit(language) {
      this.editedItem = language;
      this.open = true;
    },
    async save() {
      this.error = null;
      const language = this.editedItem;
      const validation = languageSchema.validate(language);

      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.saving = true;
        try {
          await this.$store.dispatch("languages/update", language);
          this.$refs.table.change("id", language.id, language);
        } catch (err) {
          this.error = this.errorRes(err);
        }
        this.saving = false;
      }
    }
  }
};
</script>

<style></style>
