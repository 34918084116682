<template>
  <div class="admin-add-language">
    <error :err.sync="error" />
    <form-generator
      :elements="elements"
      :data="language || {}"
      :handleUpdate="({ key }, v) => (language[key] = v)"
    />
  </div>
</template>

<script>
import languageSchema from "@/validation-schemas/language";
import FormGenerator from "@/components/form-generator.vue";
import { mapState } from "vuex";

export default {
  components: { FormGenerator },
  props: {
    language: Object
  },
  name: "admin-add-language",
  data() {
    return {
      error: null,
      loading: false
    };
  },
  computed: {
    ...mapState("ancillaries", { languages: "bookCategories" }),
    elements() {
      return [
        {
          key: "name",
          label: "name",
          type: "string"
        },
        {
          key: "iso_code",
          label: "iso-code",
          type: "string"
        },
        {
          text: "save",
          type: "button",
          className: "float-right mt-5 mt-sm-0",
          attrs: {
            loading: this.loading,
            block: this.isSmallScreen
          },
          events: {
            click: this.add
          }
        }
      ];
    }
  },
  methods: {
    async add() {
      this.error = null;
      const { language } = this;
      const validation = languageSchema.validate(language, this.joiOptions);
      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.loading = true;
        try {
          const res = await this.$store.dispatch("languages/update", {
            ...language,
            parent_language_id: language.parent_language_id
              ? language.parent_language_id.id
              : null
          });
          this.$emit("added", res.data);
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
